<!-- 订单管理-预存订单 -->

<template>
	<div class="pre-stored-orders main-cnt">
		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
				:isShowTable="false" :needExport="needExport" @export="exportExcel" :extraParame="{req_type:'2'}"
				:apiName="Order.getChargingOrderLists" :filters="filters" :columns="tableColumns"
				@calculateTotal="calculateTotal">
				<template #amount>
					<!-- <el-table :data="tableData" show-summary style="width: 100%" height="calc(100vh - 325px)">
            <el-table-column prop="or_no" label="订单编号" show-overflow-tooltip />
            <el-table-column prop="mwr_money" label="订单金额" show-overflow-tooltip />
            <el-table-column prop="or_status_text" label="订单状态" show-overflow-tooltip />
          </el-table> -->
					<div class="flex s-m-t">
						<span class="amount-title f-w-bold">合计</span>
						<span class="order-totals">{{ totalAmount.orderTotal }}</span>
					</div>
				</template>
			</common-table>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
	} from "vue";
	import {
		Order,
	} from "@/plugins/api.js";
	import {
		useStore
	} from "vuex";
	import dayjs from "dayjs";
	import getBaseUrl from "@/plugins/baseUrl.js";
	const baseUrl = getBaseUrl() ? getBaseUrl() : "";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})

	//判断是否有导出权限
	const needExport = computed(() =>{
		return authData.value.indexOf('n_1bzkYtiZuNQczQAhC47XHeMsGBRV') != -1
	})
	const exportExcel = (e) => {
		let reqData = {
			start_time: filters.value[0].value ? filters.value[0].value[0] : '',
			end_time: filters.value[0].value ? filters.value[0].value[1] : '',
			type: filters.value[1].value,
			keywords: filters.value[2].value,
			req_type: 2,
		}
		const reqDataUrl =
			`?start_time=${reqData.start_time}&end_time=${reqData.end_time}&req_type=${reqData.req_type}&keywords=${reqData.keywords}&type=${reqData.type}`
		location.href = `${baseUrl}/charge/order/export${reqDataUrl}`
	}


	/** 表格对象 */
	const roleTable = ref(null);
	// const tableData = ref([]);
	// 合计
	const totalAmount = ref({
		orderTotal: 0, // 订单合计
	})
	/** 筛选条件列表 */
	const filters = ref([{
			filterType: "date",
			name: "start_time",
			name2: "end_time",
			value: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
			placeholder: "请选择日期范围",
			type: "daterange",
		},
		{
			filterType: "select",
			name: "type",
			value: "",
			placeholder: "请选择订单类型",
			options: [{
				id: '1',
				name: '充值'
			}, {
				id: '3',
				name: '提现'
			}],
			val: "id",
			lab: "name",
		},
		{
			name: "keywords",
			filterType: "search",
			value: "",
			placeholder: "请输入订单编号或手机号查询",
		},
	]);

	/** 表格配置数据 */
	const tableColumns = ref([{
			prop: "or_no",
			label: "订单编号",
			color: "--text-color",
			showTooltip: true,
			minWidth: 120,
		},
		{
			prop: "mwr_money",
			label: "订单金额",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "mwr_type_text",
			label: "订单类型",
			showTooltip: true,
		},
		{
			prop: "or_status_text",
			label: "订单状态",
			type: "option",
			metaData: ["支付中", "已支付", "已取消"],
			colorData: ["var(--text-blue-color)", "var(--theme-color)", "var(--text-color)", ],
			showTooltip: true,
		},
		{
			prop: "mwr_time",
			label: "支付时间",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "m_username",
			label: "用户名称",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "m_mobile",
			label: "用户手机",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orpp_name",
			label: "支付方式",
			color: "--text-color",
			showTooltip: true,
		},
	]);

	// const selectDatas = ref({
	//   req_type: '2',
	//   keywords: '',
	//   type: '',
	//   start_time: '',
	//   end_time: '',
	//   limit: 20,
	//   page: 1,
	// });
	// const selectChange = (item) => {
	//   selectDatas.value.type = item.value;
	//   getLists();
	// }
	/**
	 * 
	 * 计算合计
	 * 
	 * */
	const calculateTotal = (datas) => {
		totalAmount.value = {
			orderTotal: 0,
		}
		datas.forEach(item => {
			totalAmount.value.orderTotal += Number(item.mwr_money);
		})
		totalAmount.value.orderTotal = totalAmount.value.orderTotal.toFixed(2);
	}
	// const getLists = () => {
	//   Order.getChargingOrderLists(selectDatas.value).then((res) => {
	//     if (res.Code === 200) {
	//       tableData.value = res.Data.list;
	//     } else {
	//       ElMessage.error(res.Message);
	//     }
	//   });
	// }
	onMounted(() => {
		roleTable.value.tableLoad();
		// getLists();
	});
</script>

<style lang="scss">
	.pre-stored-orders {
		font-family: "Source Han Sans CN";

		.search-input {
			width: 240px !important;
		}

		.el-row {
			border: none;
		}

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 20px 20px 30px 30px;
			}
		}
	}

	.amount-title {
		font-size: 18px;
	}

	.order-totals {
		margin-left: 250px;
		color: #1AC994;
	}
</style>